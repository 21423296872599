<template>
  <div v-if="formComponent == null" class="spin">
    <a-spin />
  </div>
  <div v-else>
    <keep-alive>
      <component :is="formComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import _ from "lodash";
import { defineAsyncComponent, shallowRef } from "vue";
export default {
  name: "CampaignForm",
  computed: {
    gameData: function() {
      return this.$store.getters.gameData;
    },
    themeData: function() {
      return this.$store.getters.themeData;
    },
    initial: function() {
      return this.$store.getters.initial;
    },
  },
  data() {
    return {
      formComponent: null,
    }
  },
  beforeMount() {
    if (!this.$route?.params?.id && (_.isEmpty(this.gameData) || _.isEmpty(this.themeData))) {
      this.$router.go(-1);
      return null;
    } else {
      this.formComponent = shallowRef(defineAsyncComponent(() => import(`@/view/${this.initial}/campaign/${this.gameData.slug}/${this.themeData.slug}/Form.vue`)));
    }
  },
}
</script>